/* Move everything down the distance of the fixed position navbar */
.main {
	margin-top: 90px;
}

/* Change the default WooCommerce button colors to match the theme */
button.single_add_to_cart_button,
a.checkout-button 
{
	background-color: #ba8b21 !important;
}

.images {
	width: 30% !important;
}