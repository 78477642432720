/*!
 * Start Bootstrap - Grayscale Bootstrap Theme (http://startbootstrap.com)
 * Code licensed under the Apache License v2.0.
 * For details, see http://www.apache.org/licenses/LICENSE-2.0.
 */

body {
    width: 100%;
    height: 100%;
    font-family: "proxima-nova", sans-serif;
    /*color: #fff;*/
    /*background-color: #000;*/
}

html {
    width: 100%;
    height: 100%;
}

/*h1, h2 {*/
    /*margin: 0 0 35px;*/
    /*text-transform: uppercase;*/
    /*font-family: "proxima-nova", sans-serif;*/
    /*font-weight: 100;*/
    /*font-size: 25px;*/
/*}*/

h3 {
    text-transform: none;
    /*font-size: 18px;*/
    margin: 0;
}

p {
    margin: 0 0 25px;
    font-size: 14px;
    line-height: 1.8;
    font-weight: 100;
    font-family: "proxima-nova", sans-serif;
}

.trademark {
    margin-top: 50px;
}


a {
    /*color: #ffffff;*/
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

a:hover,
a:focus {
    text-decoration: none;
    color: #73a4ce;
}

.light {
    font-weight: 400;
}

@media (min-width: 768px) {
    .navbar-custom {
        padding: 20px 0;
        border-bottom: 0;
        letter-spacing: 1px;
        background: 0 0;
        -webkit-transition: background .5s ease-in-out, padding .5s ease-in-out;
        -moz-transition: background .5s ease-in-out, padding .5s ease-in-out;
        transition: background .5s ease-in-out, padding .5s ease-in-out;
    }

    .navbar-custom.top-nav-collapse {
        padding: 0;
        border-bottom: 1px solid rgba(255, 255, 255, .3);
        background: #000;
    }
}

.intro {
    display: table;
    width: 100%;
    height: auto;
    padding: 100px 0;
    text-align: center;
    color: #fff;
    /*  background: url(../img/intro-bg.jpg) no-repeat bottom center scroll;
      background-color: #000; */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
}

.intro .intro-body {
    display: table-cell;
    vertical-align: middle;
}

.intro .intro-body .brand-heading {
    font-size: 40px;
}

.intro .intro-body .intro-text {
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 5px;
    margin-top: 30px;
}

@media (min-width: 768px) {
    .intro {
        height: 100%;
        padding: 0;
    }

    .intro .intro-body .brand-heading {
        font-size: 100px;
    }

    .intro .intro-body .intro-text {
        font-size: 20px;
        text-transform: uppercase;
        letter-spacing: 15px;
        margin-top: 50px;
    }
}

.btn-circle {
    width: 50px;
    height: 50px;
    margin-top: 15px;
    padding: 7px 16px;
    border: 2px solid #fff;
    border-radius: 100% !important;
    font-size: 23px;
    color: #fff;
    background: 0 0;
    -webkit-transition: background .3s ease-in-out;
    -moz-transition: background .3s ease-in-out;
    transition: background .3s ease-in-out;
}

.btn-circle:hover,
.btn-circle:focus {
    outline: 0;
    color: #fff;
    background: rgba(255, 255, 255, .1);
}

.btn-circle i.animated {
    -webkit-transition-property: -webkit-transform;
    -webkit-transition-duration: 1s;
    -moz-transition-property: -moz-transform;
    -moz-transition-duration: 1s;
}

/*.btn-circle:hover i.animated {*/
.btn-circle,
.btn-circle i.animated {
    -webkit-animation-name: pulse;
    -moz-animation-name: pulse;
    -webkit-animation-duration: 1.5s;
    -moz-animation-duration: 1.5s;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease;
    -moz-animation-timing-function: ease;
}

@-webkit-keyframes pulse {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    50% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@-moz-keyframes pulse {
    0% {
        -moz-transform: scale(1);
        transform: scale(1);
    }

    50% {
        -moz-transform: scale(1.2);
        transform: scale(1.2);
    }

    100% {
        -moz-transform: scale(1);
        transform: scale(1);
    }
}

.btn {
    border-radius: 0;
    text-transform: uppercase;
    font-family: "proxima-nova", sans-serif;
    font-weight: 400;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

select, .input-lg, .c-select {
    text-transform: uppercase;
    font-family: "proxima-nova", sans-serif;
    font-weight: 200;
    padding: 10px;
    border: 1px solid #414042;
}

.form-control {
    color: #414042;
}

.btn-lg {
    padding: 25px 30px;
}

.btn-default {
    border: 1px solid #ba8b21;
    color: #ba8b21;
    background: #ba8b21;
    /*background-color: transparent;*/
}

.btn-default:hover,
.btn-default:focus {
    border: 1px solid #414042;
    outline: 0;
    color: #414042;
    /*background-color: transparent;*/
}

.modal .btn-default {
    color: #FFF;
    background-color: #ba8b21;
    width: 100%;
}

.modal {
    z-index: 100000
}

.modal .btn-default:hover,
.modal .btn-default:focus {
    border: 1px solid #ba8b21;
    outline: 0;
    color: #ba8b21;
    background-color: transparent;
}

.modal-header {
    border: none;
    background-color: #ba8b21;
    text-align: center;
}

.modal-content {
    border-radius: 0px;
}

.modal-sub {
    color: #414042;
    text-align: center;
}

.close {
    font-weight: 200;
    color: #ffffff;
    text-shadow: none;
    opacity: 1;
}

/* ------------------------------------- VIDEO -------------------------*/

#video-viewport {
    position: absolute;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    top: 0;
    overflow: hidden;
    z-index: 0; /* for accessing the video by click */
    max-height: 100vh;

    -webkit-animation: showVideo .6s forwards 1s;
    animation: showVideo .6s forwards 1s;

}

#wistia_grid_15_wrapper, .wistia_responsive_padding, .wistia_responsive_wrapper, #wistia_chrome_11, #wistia_grid_15_main {
    min-width: 100% !important;
    min-height: 100% !important;
    width: auto;
    height: auto;
    max-height: 100vh;
}

#debug {
    position: absolute;
    top: 0;
    z-index: 100;
    color: #fff;
    font-size: 12pt;
}

/****MEET YOUR MENTORS****/
.mentor {
    padding: 100px 0;
    background-color: #e9e9e9;
    /*text-align: justify;*/
}

.mentor .lead {
    font-weight: 400;
    color: #ba8b21;
}

.mentor h2 {
    text-align: center;
    color: #ba8b21;
}

.mentor-bio {
    background-color: #FFF;
    margin: 20px 0;
    padding: 50px;
    border: 1px solid #DEDEDE;
}

.mentor p {
    font-size: 13pt;
    font-weight: 200;
    color: #555;
}

.mentor-bio .line {
    margin: 0px;
    margin-bottom: 2em;
}

ul.banner-social-buttons {
    margin-top: 0;
}



.signup-form::-moz-selection {
    text-shadow: none;
    background: #fcfcfc;
    background: rgba(255, 255, 255, .2);
}

.signup-form::selection {
    text-shadow: none;
    background: #fcfcfc;
    background: rgba(255, 255, 255, .2);
}

::selection {
    background: #ffcc00;
    color:#FFF;
}
::-moz-selection {
    background: #ffcc00;
    color:#FFF;
}

img::selection {
    background: 0 0;
}

img::-moz-selection {
    background: 0 0;
}

body {
    webkit-tap-highlight-color: rgba(255, 255, 255, .2);
}

#video-viewport {
    z-index: -30;
}

/****SOCIAL MEDIA ICONS****/

.social-wrap {
    margin-top: 25px;
}

/****PHILANTHROPY****/

#Modal04 .modal-dialog {
    width: 48%;
}

#Modal04 .modal-dialog a {
    color: #ba8b21;
}

#Modal04 .modal-dialog a:hover {
    color: #000;
}

#Modal04 p {
    color: #2f2f2f;
}

#Modal04 p span {
    font-weight: bold;
    display: block;
}

#Modal04 .col-md-4 {
    height: 100%;
}

#Modal04 .col-md-4 div {
    display: inline-block;
    vertical-align: middle;
    font-size: 0;
}

#Modal04 img {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
}

.phil-button {
    background: #ba8b21;
    border: #ba8b21;
    margin-bottom: 15px;
}

.phil-intro {
    padding: 0 15px;
}

@media (max-width: 1024px) {
    #Modal04 .modal-dialog {
        width: auto;
    }
}

@media only screen and (min-width: 480px) and (max-width: 991px) {
    #Modal04 img {
        display: block;
        width: 50%;
        margin: 0 auto 10px auto;
    }
}

@media (max-width: 480px) {
    #Modal04 img {
        display: block;
        width: 80%;
        margin: 0 auto 10px auto;
    }
}

/******************************************
    Typography
******************************************/
.lead {
    font-size: 19px;
    line-height: 30px;
}

.description {

}

.checkbox {
    color: #555;
}

/******************************************
    Misc
******************************************/
.section {
    padding: 100px 0;
}

.btn-default {
    background: #ba8b21;
    color: #FFF;
    font-weight: 700;
    padding: 10px 30px;
    font-size: 16px;
}

.modal-title {
    text-transform: uppercase;
    font-weight: 700;
    color: #FFF;
}

.btn-circle {
    display: block;
    margin: 30px auto 0 auto;
}

.btn-gray {
    background: #969696;
    color: #FFF;

}

.btn-gray:hover,
.btn-gray:active,
.btn-gray:focus {
    background: #5f5f5f;
    color: #FFF;
}


.alert {
    margin-bottom: 0;
}

/******************************************
    Modals
******************************************/
.video-modal .modal-header{
    background: #FFF;
    /*min-height: 70px;*/
}

.video-modal .modal-title {
    color: #555;
}

.video-modal .modal-footer {
    text-align: center;
}

.video-modal .close {
    color: #737373;
}

.modal .row {
    margin: 10px auto;
}

/******************************************
    Navigation
******************************************/
.navbar {
    text-transform: uppercase;
}

.navbar-fixed-top {
    z-index: 10;
}

.navbar-brand>img {
    max-width: 210px;
}


/******************************************
    Main Header
******************************************/
.header__content {
    z-index: 5;
}

.intro__h1 {
    font-size: 90px;
    font-weight: 700;
}

.intro__h2 {
    font-size: 34px;
    margin-bottom: 15px;
    font-weight: 100;

    margin-top: 15px;
}

.intro__text {
    /*width: 81%;*/
    margin: 0 auto 30px auto;

    width: 95%;
    font-size: 15px;
    line-height: 19px;
    font-weight: 400;
    text-align: justify;
}

.header__content .lead {
    font-weight: 100;
    letter-spacing: 1px;
}

.header-logo {
    /*margin: 60px 0;*/
    margin: 20px auto 5px auto;
    max-width: 420px;
}

.intro hr {
    width: 10%;
    margin: 30px auto;
}

.video-overlay {
    background: rgba(0,0,0,0.8);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 4;
}

.header__register-form {
    background: rgba(230, 230, 230, 0.14);
    padding: 40px;
    margin-top: 30px;
    border: 1px solid rgba(255, 255, 255, 0.04);
    /*margin: 40px 0;*/
}

.header__register-form h2 {
    margin-top: 0px;
}

.register-btn {
    background: #ba8b21;
    /*margin-top: 15px;*/
    font-weight: 900;
    margin-bottom: 15px;
    height: 45px;
}

.btn-play {
    margin: 30px auto 0 auto;
    background: #b53c3c;
    color: #FFF;
    font-weight: 700;
    padding: 6px 20px;
    font-size: 14px;
}

.btn-play:hover,
.btn-play:focus,
.btn-play:active {
    background: #752626;
    color: #FFF;
}

/******************************************
    Book Section
******************************************/
.book-text {
    width: 100%;
    padding: 100px 0;
    /*text-align: center;*/
    background-color: #ba8b21;
    /*background-color: #3a478e;*/
    color: #FFF;
}

.book-text h1 {
    text-transform: uppercase;
}

.book-text h2 {
    margin-bottom: 30px;
    text-transform: uppercase;
}

.book-text .btn-default {
    border: 1px solid #FFF;
    color: #FFF;
    background-color: transparent;
}

.description {
    font-size: 17px;
    line-height: 27px;
    text-align: justify;
}

.book-cover {
    margin-top: 60px;
}


/******************************************
    Program Section
******************************************/
.program__section {
    /*background: url("../img/movement-bg.jpg") no-repeat;*/
    /*background-size: cover;*/
    background: #FFF;
    color: #555;
}

.program__section .btn-dropdown {
    /*border: 1px solid #ba8b21;*/
    /*color: #ba8b21;*/
    /*background-color: transparent;*/
    font-size: 12px;
    padding: 10px;
    float: right;
}

.program__section h2 {
    font-size:20px;
}

.program__section h2,
.program__section button {
    display: inline;
}

.program__section button {
    text-align:right;
    /*float:right;*/
}

.question-box {
    margin: 30px 0;
}

.program__dropdown {
    font-size: 17px;
    line-height: 27px;
    text-align: justify;
    background: #f7f7f7;
    border: 1px solid #e8e8e8;
    padding: 25px;
    margin-bottom: 30px;
}


/******************************************
    Mentor Section
******************************************/
.mentor-bio {
    -webkit-box-shadow: 0px 15px 17px -10px rgba(0,0,0,0.5);
    -moz-box-shadow: 0px 15px 17px -10px rgba(0,0,0,0.5);
    box-shadow: 0px 15px 17px -10px rgba(0,0,0,0.5);
}

.mentor p {
    text-align: justify;
}

.mentor-bio-img {
    margin-bottom: 15px;
}

.quote {
    font-size: 25px;
    font-weight: 200;
    margin: 30px 0;
    text-transform: uppercase;
    display: block;
}

/*.bio-social-media */
.btn-social-icon {
    background: #ba8b21;
    margin-right: 15px;
    color: #FFF;
    padding: 20px;
    /*margin-top: 30px;*/
    height: 42px !important;
    width: 42px !important;
    top: -2px;
}

.btn-social-icon>:first-child {
    top: 6px !important;
}

.bio-social-media .btn-default {
    margin-right: 15px;
}

.bio-social-media a {
    color: #FFF;
}

/******************************************
    Movement Section
******************************************/
.movement__section {

}


/******************************************
    Form Styles
******************************************/
.signup-form {
    background: #f7f7f7;
    border: 1px solid #e8e8e8;
    /*padding: 74px 50px;*/
    padding: 40px 0;
}

.signup-form .btn-default {
    width: 100%;
}

.form-control {
    height: 45px;
    border-radius: 0px;
    margin-bottom: 15px;
}

select.squarecorners{
    border: 0;
    outline: 1px solid #CCC;
    background-color: white;
}

/******************************************
    Audio Player
******************************************/
.audio-player-table.table>tbody>tr>td {
    padding: 13px 8px;
    line-height: 2;
    /*border-left: 1px solid #dddddd;*/
    /*border-right: 1px solid #dddddd;*/
}

.audio-player-table thead tr {
    background: #ba8b21;
    color: #fff;
    border: 0;
    height: 39px;
}

.audio-player-table tbody tr th {
    padding-top: 46px;
}

.audio-player-table tbody tr td {
    text-align: center;
}

.audio__title {
    margin: 0 0 10px 0;
    line-height: 1.5;
}

.btn-audio-player {
    background: #ececec;
    border: 1px solid #e2e2e2;
}

/******************************************
    Footer
******************************************/
footer {
    background-color: #333;
    color: #FFF;
    padding: 100px 0;
}

footer p {
    margin: 0;
    font-size: 11px;
}

.foot-img {
    margin: 0 auto 20px auto;
    /*margin-bottom: 15px;*/
    max-width: 300px;
    text-align: center;
}


/******************************************
    Responsive
******************************************/
@media (max-width: 1000px) {
    .navbar-header {
        float: none;
    }
    .navbar-left,.navbar-right {
        float: none !important;
    }
    .navbar-toggle {
        display: block;
    }
    .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
    }
    .navbar-fixed-top {
        top: 0;
        border-width: 0 0 1px;
    }
    .navbar-collapse.collapse {
        display: none!important;
    }
    .navbar-nav {
        float: none!important;
        margin-top: 7.5px;
    }
    .navbar-nav>li {
        float: none;
    }
    .navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .collapse.in{
        display:block !important;
    }
}

@media (min-width:1px) and (max-width: 480px) {

    /******************************************
        Misc.
    ******************************************/
    .section {
        padding: 40px 0;
    }


    /******************************************
        Header
    ******************************************/
    .intro__h1 {
        font-size: 30px;
    }

    .header-logo {
        max-width: 100%;
    }

    .header__register-form {
        padding: 0 40px;
    }

    .intro {
        padding: 0;
        margin-top: 51px;
    }

    .btn-circle {
        margin: 20px auto;
    }

    /******************************************
        Movement Section
    ******************************************/
    .movement__section img {
        margin: 30px auto 0 auto;
        width: 50%;
    }

    /******************************************
        Book Section
    ******************************************/
    .book-text {
        padding: 30px 0;
    }

    .book-text h2 {
        font-size: 21px;
    }

    .book-text h1 {
        font-size: 26px;
    }

    .book-cover {
        margin: 30px auto 0 auto;
        width: 50%;
    }

    /******************************************
        Program Section
    ******************************************/
    .program__section .btn-dropdown {
        float: none;
        display: block;
        margin-top: 14px;
    }

    /******************************************
        Mentor Section
    ******************************************/
    .mentor {
        padding: 0 0;
    }

    .bio-social-media .btn-default {
        display: block;
        text-align: center;
        margin-right: 0;
    }

    .btn-social-icon {
        margin-right: 6px;
        margin-top: 15px;
    }

    .bio-social-media {
        text-align: center;
    }

    .mentor-bio {
        padding: 50px 15px;
    }

    .mentor .lead {
        font-size: 23px;
    }


    .signup-form {
        padding: 30px;
    }

    .video-modal iframe {
        height: 300px;
    }
    
}


@media (min-width:481px) and (max-width: 767px) {

    /******************************************
        Misc.
    ******************************************/
    .section {
        padding: 80px 0;
    }


    /******************************************
        Header
    ******************************************/
    .intro__h1 {
        font-size: 52px;
    }

    .header-logo {
        max-width: 60%;
    }

    .header__register-form {
        padding: 0 40px;
    }

    .register-btn {
        width: 100%;
    }

    .intro {
        padding: 0;
        margin-top: 51px;
    }

    .btn-circle {
        margin: 20px auto;
    }

    /******************************************
        Movement Section
    ******************************************/
    .movement__section img {
        margin: 30px auto 0 auto;
        width: 50%;
    }

    /******************************************
        Book Section
    ******************************************/
    .book-text {
        padding: 80px 0;
    }

    .book-text h2 {
        font-size: 21px;
    }

    .book-text h1 {
        font-size: 26px;
    }

    .book-cover {
        margin: 30px auto 0 auto;
        width: 30%;
    }

    /******************************************
        Program Section
    ******************************************/
    .program__section .btn-dropdown {
        float: none;
        display: block;
        margin-top: 14px;
    }

    /******************************************
        Mentor Section
    ******************************************/
    .mentor {
        padding: 0 0;
    }

    .bio-social-media {
        text-align: center;
    }

    .bio-social-media .btn-default {
        display: block;
        text-align: center;
        margin-right: 0;
    }

    .btn-social-icon {
        margin-right: 6px;
        margin-top: 15px;
    }

    .mentor-bio {
        padding: 50px 15px;
    }

    .mentor .lead {
        font-size: 23px;
    }


    .signup-form {
        padding: 30px;
    }

    .video-modal iframe {
        height: 300px;
    }

}



@media (min-width:768px) and (max-width: 991px) {
    .intro__h1 {
        font-size: 50px;
        font-weight: 700;
    }

    .book-cover {
        width: 40%;
        text-align: center;
        margin: 0 auto;
    }

    .video-modal iframe {
        height: 300px;
    }

}


@media (max-width: 1199px) {
    ul.banner-social-buttons {
        margin-top: 15px;
    }
}

@media (max-width: 767px) {

    /*.intro {*/
        /*background: url(../img/hero-video.jpg) no-repeat bottom center scroll;*/
        /*background-color: #000;*/
    /*}*/

    ul.banner-social-buttons li {
        display: block;
        margin-bottom: 20px;
        padding: 0;
    }

    ul.banner-social-buttons li:last-child {
        margin-bottom: 0;
    }

    .mentor img {
        margin: 0 auto;
        padding-bottom: 30px;
    }

    /*.mentor .lead {*/
        /*font-size: 30px;*/
        /*!*text-align: center;*!*/
        /*margin-top: 20px;*/
    /*}*/

}
